import { init, captureException } from '@umengfe/apm'
import { WY_APPKEY, HD_APPKEY, OTHERS_APPKEY, PKG_LIST, IGNORE_ERROR } from '@/constants/umeng'
import { isWechat, isFromApp, isAndroid, isIos } from '@/utils'

export const umengInit = (app) => {
  const env = window?.checkEnv() === 'prod' ? 'PROD' : 'TEST'
  const pidMap = { wy: WY_APPKEY, hd: HD_APPKEY }
  const appkey = pidMap[window?.GLOBAL_FR] || OTHERS_APPKEY
  const payload = {
    pid: appkey[env],
    tag: isWechat() ? 'wechat' : isFromApp() ? 'app' : 'web',
    pkgList: isAndroid() ? PKG_LIST.ANDROID : isIos() ? PKG_LIST.IOS : '',
    errorFilter: {
      mode: 'ignore',
      rules: IGNORE_ERROR,
    },
  }
  init(payload)
  
  app.config.errorHandler = (err: any) => {
    console.error('err', err)
    captureException(err)
  }
}

/**
 * sendAPILog的method映射表
 */
const LOG_METHODS = {
  GET: 1,
  POST: 2,
  PUT: 3,
  HEAD: 4,
  DELETE: 5,
  CONNECT: 6,
  OPTIONS: 7,
  TRACE: 8,
  PATCH: 9,
}

/**
 * 友盟u-web事件映射表，记录事件类型
 * 用于手动记录需要跟踪的事件，一些特定的错误，或者异常都可以使用该map
 */
const EVENT_MAP = {
  BUSINESS_EVENT: '_businessEvent', // 业务事件
  JS_CUSTOM_EVENT: '_jsCustomEvent', // JS异常
  PLACE_ORDER_PROCESS: '_placeOrderProcess', // 下单埋点
}

type MapValues = (typeof EVENT_MAP)[keyof typeof EVENT_MAP]

/**
 * 友盟u-web数据上报
 */
const sendLogToUWeb = function (eventName: MapValues, category: string, label?: string, value?: number) {
  if (window?._czc) {
    window._czc?.push(['_trackEvent', eventName, category, label, value])
  }
}

export { LOG_METHODS, sendLogToUWeb, EVENT_MAP }

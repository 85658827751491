import { isHd } from '@/utils'

export const state = () => {
  return {
    headerInfo: {
      'c-id': '',
      'c-meta': '',
    },
    isRefreshAppInputToken: false,
    isShowLoading: false, //显示loading
    isShowNewSource: true, //是否是app新包,根据桥方法judgeNewVersion判断,微信公众号默认true
    appData: {
      secret: '',
      session: '',
      userInfo: {},
      os: isHd() ? '104018' : '104006',
      userId: '',
      basic: {},
      growth: {},
      stat: {},
      info: {},
      vip: {},
      busiOpen: 0,
      userSig: '',
      version: '',
    }, //核心数据
    bucket: 0, // AB Test分组标签
    nextPage: { path: '', query: {} }, // 原目标跳转路由
    sourcePage: { path: '', query: {} }, //路由来源页
    locationData: {
      position: { lng: '', lat: '' },
      longitude: '',
      latitude: '',
      cityName: '',
      cityCode: '',
      locationAddress: '',
      formattedAddress: '',
    }, //定位失败默认值
    nearAddressList: [], //地图附近地址列表
    hdKeepAliveInclude: [
      'tabModuleIndex',
      'tabModuleServiceIndex',
      'tabModuleOrderIndex',
      'tabModuleMyIndex',
      'bookingOrder',
    ],
    keepAliveInclude:  [
        'myOrders',
        'myModuleHome',
        'videoModule',
        'bookingOrder',
        'trendsList',
        'technicianList',
        'homeModule',
        'indexModule',
        'growthCenter',
        'unAttentionTechList',
        'partnerInviteFriends',
        'allCourse',
        'imMessage',
        'inviteDetail',
      ], //include
    allDict: [], //字典数据
    keyStr: '', //
    keyInfo: null,
    orderAddress: {}, //新增/编辑地址监听
    orderConfimAddress: {}, //提交订单时切换地址/提交地址
    isUpdateOrderConfimAddress: '', //是否更换地址
    showObj: {}, //作品数据
    currentModel: 'conversation', //联系客服默认列表模式
    conversationList: [], //im聊天列表
    shopOrderInfo: {}, //门店下单数据
    unlikeIdList: { storeIds: [], techIds: [], ignoreShowTechIds: [] }, //不喜欢的
    imCustomlocation: {
      description: '',
      longitude: '',
      latitude: '',
    },
    productBuyCountShow: 0, //是否展示套餐销售量
    editAddress: {}, //修改订单时的地址
    dynamicShow: 1, //发现栏 是否展示[1=展示]
    isShowCouponsActivity: false, //是否展示优惠券活动，根据城市变化 false:不展示  true:展示
    androidChannel: '0', //审核平台来源，0：默认，1：华为， 2：应用宝， 3：小米， 4：oppo， 5：vivo  6：三星
    unOpenCityData: {
      cityAreaCode: '', //定位获取的未开通城市区号
      lat: '', //经纬度
      lng: '', //经纬度
    },
    isBigAccount: true,
    backFromVote: false,
    inMuted: 0, // 视频是否禁音
    isCloseOnOrderGoing: false,
    phoneStatusBarHeight: 0,
    changedAvatar: '',
    mapChangeTimeStamp: 0,
    mapChangeLoc: {
      cityCode: '',
      cityName: '',
      longitude: '',
      latitude: '',
    },
    isShowRedEnvolopeEntrance: false, //是否展示红包雨入口
    isInRedPack: false, //是否是三端展示红包雨入口
    redTips: 0, //红包雨次数
    isPickupRedPackCoupon: false, //领取20元红包日期以及是否领取
    // pickupRedPackCoupon: {//领取20元红包日期以及是否领取
    //   isPickup: false,
    //   pickupDate: ''
    // },
    tabbarIndex: 0, //汗滴底部tabbar选中索引
    vipcardInfo: {
      VipCardForMonthMoney: 0, //开通vip卡的价格
      isVip: true, //是否是vip
      vipDiscount: 1, //vip折扣
    },
    pointInfo: {
      pointAmount: 0,
      expiringPoint: 0,
      expiringDays: 0,
      usageCases: [],
    },
    isChooseAliPay: false, //是否选择了支付宝
    isFromMoreOrderBtn: false, //点击再来一单
    hdOpenCityIds: '', //汗滴开通城市
    isClickFromOrder: false, //是否点击订单处在线联系
    nowCurrentConversationID: '', //im聊天当前ConversationID
    imChatOrderId: 0, //im聊天订单Id
    canDoCallAudio: true, // 能否拨语言电话
    isFromMsc: false, // 是否来自消息中心,
    callRoomId: 0, // 语言电话房间Id
    isImCusMessageClick: false, // im聊天
    imShareLocationTechId: '', // 分享定位技师Id
    imCanChatTechId: '', // 当前可聊天的技师Id
    imCustomOrderId: '', // 聊天消息中的订单Id
    imCustomTechId: '', // 聊天消息中的技师Id
    imCustomVipCardId: '', // 聊天消息中的会员卡Id
    imCustomH5OutUrl: '', // 聊天消息中的h5外部地址
    imIsFromOtherPage: '', // 汗滴技师列表
    imCustomAmap: {
      myLng: 0,
      myLat: 0,
      locInfo: '',
    }, // 聊天消息中的地图信息
    footPrintTechIds: [], // 技师浏览足迹所有Id
    doCallActionTime: 0, // 拨打语言电话时刻
    latestUserId: '', //上一次登录的以后id
    currentPetitionTechName: '', //当前请愿的技师名称
    urlChannelCode: '', //url传入的channelCode
    hasCookie: 0, //判断汗滴flutter是否注入cookie
    cityChangeTimeStamp: 0, // 切换城市页面点击
    cityChangeLoc: { // 汗滴app城市切换
      adcode: '',
      cityCode: '',
      cityName: '',
      longitude: '',
      latitude: '',
    },
    changeServeTimestamp: 0, // 汗滴flutter原生端切换到服务页
    changeServeType: 0, // 汗滴flutter原生端切换到服务页tab切换
    changeOrderType: 0, // 汗滴flutter原生端切换到订单页tab切换
    nowNearAddress: [], // 地图拖动或者点击后，当前附近地址
    isHdBigCity: false, //汗滴默认是大城市
    globalVoiceStatus: 1, //0非静音，1静音
    umengOrder: {
      origin: '' //来源
    },
    isNeedRefreshTopicList: true,
    topicScrollTop: 0,
    serviceTel: '', // 400电话
    hdAppFlutterPayTimestamp: 0,
    hdAppFlutterPayResult: {
      method: '', // 支付方式 支付宝："alipay"；微信："wechatPay"
      result: '' // 支付结果
    }, // 汗滴app支付结果
    ossBaseHref: '', // OSS公有空间基准URL
    hideGlobalRemainAmt: false // **显示余额
  }
}

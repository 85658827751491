// 个别页面直接进来，比如协议页面，就不需要走初始化逻辑了，同时右下角回到首页展示隐藏
const isNotNeedInit = () => {
  const routeNameList = [
    '/pages/tencentNews/index',
    '/pages/rule/productDesc',
    '/pages/rule/dutyAvowal',
    '/pages/bindWechat/tipsForBind',
    '/pages/rule/refundProcess',
    '/pages/rule/managePrivacyPolicy',
    '/pages/rule/phoneProtect',
    '/pages/rule/question',
    '/pages/rule/privacyPolicy',
    '/pages/rule/userProxy',
    '/pages/rule/userGuide',
    '/pages/rule/vipcardAgreement',
    '/pages/updateDescription/index',
    '/pages/healthKnowledge/index',
    '/pages/techOperate/index',
    '/pages/community/index',
    '/pages/newbieTask/index',
    '/pages/rule/hdYszc',
  ]
  if (routeNameList.some((x) => window.location.href.includes(x))) {
    return true
  }
}
//个别app分享页面，带isApp=1后缀，但是只在公众号打开，或者有些页面不需要定位，特殊处理下
const isNotLocationInit = () => {
  const routeNameList = [
    '/pages/bindWechat/index',
    '/pages/bindWechat/bindSuccess',
    '/pages/sharepay/index',
    '/pages/recruit/index',
    '/pages/sharepay/paySuccess',
    '/myModule/login/index',
    'pages/survey/user',
    '/myModule/growthCenter/getVipCard',
    '/pages/invite/partner',
    '/pages/invite/techRegister',
    '/orderModule/payState/success',
    '/orderModule/payState/success',
    '/pages/wynh/index',
    '/pages/videoVcPage/index',
    '/pages/shareVideo/index',
    '/pages/invite/invitee',
    '/pages/invite/poster'
  ]
  if (routeNameList.some((x) => window.location.href.includes(x))) {
    return true
  }
}

export { isNotNeedInit, isNotLocationInit }

import { apiInit } from '@/utils'
let urlList = {
  getAes: {
    //获取秘钥
    url: 'v5/secret/getAES',
    type: 'post',
  },
  homePageSetting: {
    //首页配置
    url: 'v5/apppagesetting/customer/index',
    type: 'post',
  },
  getAllAdBanners: {
    //获取首页轮播图
    url: 'v3/app/banner/GetUserVerBanners',
    type: 'get',
  },
  getProducts: {
    //获取首页套餐
    url: 'v3/product/appGetProductsByCondition',
    type: 'post',
  },
  getRecommendTech: {
    //获取首页推荐技师
    url: 'tech/recList',
    type: 'post',
  },
  getServedTechnicianList: {
    //获取服务过的技师
    url: 'v5/document/technician/GetMyServedTechnicianList',
    type: 'post',
  },
  getTechnicianList: {
    //获取技师列表
    url: 'tech/list',
    type: 'post',
  },
  getTechnicianDetail: {
    //获取技师详情
    url: 'tech/detail',
    type: 'post',
  },
  getProductDetail: {
    //获取套餐详情
    url: 'v5/product/getProductById',
    type: 'post',
  },
  getServiceTime: {
    //获取技师服务时间
    url: 'v4/service/openTime',
    type: 'post',
  },
  getMyAddressList: {
    //获取我的服务地址
    url: 'v3/address/GetMyAddressList',
    type: 'post',
  },
  getWeatherInfo: {
    //获取天气信息
    url: 'v4/area/weather/info',
    type: 'post',
  },
  getAllCoupons: {
    //自动领取优惠券
    url: 'v5/order/create/getDiscounts',
    type: 'post',
  },
  getTechniciannTime: {
    //获取技师服务时间
    url: 'v3/document/technician/GetTechniciannTime',
    type: 'post',
  },
  getCarFee: {
    //获取技师服务时间
    url: 'v3/order/GetCarFee',
    type: 'post',
  },
  createOrder: {
    //确认下单
    url: 'v6/order/create/CreateOrder',
    type: 'post',
  },
  getUserOrders: {
    //获取我的订单
    url: 'v5/order/info/GetUserOrders',
    type: 'post',
  },
  getOrderDetail: {
    //获取订单详情
    url: 'v5/order/info/GetUserOrderById',
    type: 'post',
  },
  modifyOrder: {
    //修改订单
    url: 'v5/order/modify/ModifyOrder',
    type: 'post',
  },
  deleteOrder: {
    //删除订单
    url: 'v3/order/deleteOrder',
    type: 'post',
  },
  getOrderUserPhone: {
    //获取技师号码
    url: 'v3/order/getOrderUserPhone',
    type: 'post',
  },
  getUserInfo: {
    //获取我的信息
    url: 'v6/document/userinfo',
    type: 'post',
  },
  getTrendsList: {
    //获取我的动态
    url: 'v6/show/discover/list',
    type: 'post',
  },
  getTrendsDetail: {
    //获取动态详情
    url: 'v5/show/discover/detail',
    type: 'post',
  },
  getTrendsComment: {
    //获取动态详情评论列表
    url: 'v5/show/comment/client/firstListWithOutShowDetail',
    type: 'post',
  },
  getEvaClassifyNumber: {
    //获取评价分类
    url: 'v5/comment/show/commentCountByScoreClassify',
    type: 'post',
  },
  getEvaClassify: {
    //获取评价分类数
    url: 'v5/comment/show/commentCountGroupByTag',
    type: 'post',
  },
  getEvaList: {
    //获取评价列表
    url: 'v5/comment/show/list',
    type: 'post',
  },
  likeComment: {
    //点赞评价
    url: 'v5/comment/show/LikeComment',
    type: 'post',
  },
  delOrderComment: {
    //删除评价
    url: 'v5/order/comment/delOrderComment',
    type: 'post',
  },
  getOpenCityList: {
    //获取已开通城市
    url: 'v3/document/area/GetOpenProvinceGroup',
    type: 'get',
  },
  getRecommendProducts: {
    //获取推荐套餐
    url: 'v3/product/recommend',
    type: 'post',
  },
  getMsgCount: {
    //获取未读信息数量
    url: 'v3/msgCount/userMsgCount',
    type: 'post',
  },
  getVipCardList: {
    //获取会员卡列表
    url: 'v3/vipCard/list',
    type: 'get',
  },
  getPaymentMode: {
    //获取支付方式列表
    url: 'pay/control/queryPaymentMode',
    type: 'post',
  },
  payProductOrder: {
    //支付产品订单
    url: 'pay/control/pay',
    type: 'post',
  },
  payAddClock: {
    //支付加钟
    url: 'pay/control/addclock/pay',
    type: 'post',
  },
  payAddClockNew: {
    //支付加钟（新接口）
    url: 'pay/control/addClock',
    type: 'post',
  },
  payReward: {
    //支付打赏
    url: 'pay/control/userRewardOrder/pay',
    type: 'post',
  },
  userOpenVip: {
    //开通会员/续费
    url: 'pay/control/userOpenVip/pay',
    type: 'post',
  },
  getAllDict: {
    //获取字典数据
    url: 'v5/document/dict/getAllDict',
    type: 'post',
  },
  getSelectedDict: {
    //获取字典数据(根据传的codes取需要的)
    url: 'v5/document/dict/GetSelectedDict',
    type: 'post',
  },
  getMyComment: {
    //获取我的评价
    url: 'v5/order/comment/listMyOrderComment',
    type: 'post',
  },
  getDiscounttecList: {
    //获取定制券列表
    url: 'v5/document/technician/discountteclist',
    type: 'post',
  },
  getSubsidyList: {
    //获取专享券列表
    url: 'v3/wallet/subsidy/list',
    type: 'post',
  },
  getSubsidy: {
    //领取专享券
    url: 'v3/wallet/subsidy/userget',
    type: 'get',
  },
  getTicket: {
    //技师定制券
    url: 'v3/wallet/ticket/userget',
    type: 'get',
  },
  getTotalCoupons: {
    //获取优惠券总数
    url: 'v6/coupons/mis/totalnum',
    type: 'post',
  },
  getWallectBill: {
    //获取账单数据
    url: 'v5/document/wallect/recharge-refund-buy/record',
    type: 'post',
  },
  getFeedbackList: {
    //获取反馈记录
    url: 'v3/feedback/UserQuestionList',
    type: 'post',
  },
  addFeedback: {
    //新增反馈记录
    url: 'v3/feedback/addFeedback',
    type: 'post',
  },
  getReportList: {
    //获取举报记录
    url: 'v3/feedback/GetInformAgainstByUser',
    type: 'post',
  },
  getReportDetail: {
    //获取举报详情
    url: 'v3/feedback/informagainstreplylist',
    type: 'get',
  },
  addReportMore: {
    //举报补充说明
    url: 'v3/feedback/againstsupplement',
    type: 'post',
  },
  addReport: {
    //新增举报
    url: 'v3/feedback/CreateOrUpdateInformAgainstUser',
    type: 'post',
  },
  reportAgain: {
    //再次处理举报
    url: 'v3/feedback/againstcommitagain',
    type: 'post',
  },
  cancelReport: {
    //取消举报
    url: 'v3/feedback/cancelInformAgainstUserById',
    type: 'get',
  },
  getFeedbackDetail: {
    //获取反馈详情
    url: 'v3/feedback/questionsreplylist',
    type: 'get',
  },
  addFeedbackMore: {
    //反馈补充说明
    url: 'v3/feedback/questionssupplement',
    type: 'post',
  },

  cancelFeedback: {
    //取消反馈
    url: 'v3/feedback/deleteUserQuestionList',
    type: 'post',
  },
  feedbackAgain: {
    //再次处理反馈
    url: 'v3/feedback/questionscommitagain',
    type: 'post',
  },
  feedbackClose: {
    //反馈处理完成/关闭
    url: 'v3/feedback/questionsclose',
    type: 'post',
  },
  closeReport: {
    //关闭举报问题
    url: 'v3/feedback/againstclose',
    type: 'post',
  },
  sendSms: {
    //发送验证码
    url: 'v5/sms/send',
    type: 'post',
  },
  loginBySms: {
    //手机验证码登录
    url: 'v6/document/loginBySms',
    type: 'post',
  },
  loginByWeChat: {
    //微信登录
    url: 'v6/document/loginByWeChat',
    type: 'post',
  },
  logout: {
    //退出登录
    url: 'v3/document/logout',
    type: 'delete',
  },
  getMyCoupons: {
    //我的优惠券列表
    url: 'v6/coupons/mis/list',
    type: 'post',
  },
  addOrRemove: {
    //不喜欢该技师
    url: 'v5/unlike/addOrRemove',
    type: 'post',
  },
  technicianMap: {
    //地图模式技师列表
    url: 'v5/document/technician/technicianMap',
    type: 'post',
  },
  getShowServiceMeCount: {
    //展示是否服务过我
    url: 'v3/setting/isShowServiceMeCount',
    type: 'get',
  },
  getOpenCitys: {
    //获取已开通城市
    url: 'v3/document/area/GetOpenProvinceGroup',
    type: 'get',
  },
  getBookingOrderInfo: {
    //获取下单页订单信息
    url: 'v6/order/prepare/BeforeCreateOrder',
    type: 'post',
  },
  getMyNearAddress: {
    //从用户地址中取最近的一个地址
    url: 'v3/address/GetMyNearAddress',
    type: 'get',
  },
  addAddress: {
    //新增地址
    url: 'v3/address/CreateOrUpdateUserAddress',
    type: 'post',
  },
  deleteAddress: {
    //删除地址
    url: 'v3/address/DeleteAddress',
    type: 'post',
  },
  cancelOrder: {
    //取消订单
    url: 'v3/order/cancelOrder',
    type: 'post',
  },
  getMyFavProduct: {
    //我的收藏/套餐
    url: 'v3/document/fav/myFavProductList',
    type: 'post',
  },
  getMyFavStore: {
    //我的收藏/门店
    url: 'v3/document/fav/myFavStoreList',
    type: 'post',
  },
  addFav: {
    //添加收藏
    url: 'v3/document/fav/addFav',
    type: 'post',
  },
  cancelFav: {
    //取消收藏
    url: 'v3/document/fav/cancelFav',
    type: 'post',
  },
  getMyAttention: {
    //我的关注技师
    url: 'v5/document/fav/myFavTUserList',
    type: 'post',
  },
  setOnlineRemind: {
    //关注技师上线，提醒我
    url: 'v3/document/fav/setOnlineRemind',
    type: 'post',
  },
  praiseShowComment: {
    //点赞/取消作品评论点赞
    url: 'v5/show/comment/like',
    type: 'post',
  },
  praiseShow: {
    //点赞/取消作品点赞
    url: 'v5/show/like',
    type: 'post',
  },
  sendComment: {
    //发送作品评论
    url: 'v5/show/comment/submit',
    type: 'post',
  },
  getCommentSecondlist: {
    //作品二级评论列表
    url: 'v5/show/comment/client/secondlist',
    type: 'post',
  },
  delComment: {
    //删除作品评论
    url: 'v5/show/comment/del',
    type: 'post',
  },
  getClientList: {
    //技师主页动态列表
    url: 'v5/show/client/list',
    type: 'post',
  },
  getRewardRecord: {
    //获取打赏记录
    url: 'v3/order/userRewardOrderList',
    type: 'post',
  },
  getRefundMoneyByOrder: {
    //获取可退款金额
    url: 'v5/orderRefund/GetRefundMoneyByOrder',
    type: 'post',
  },
  applyRefundMoney: {
    //申请退款
    url: 'v5/orderRefund/Apply',
    type: 'post',
  },
  cancelRefoundsApply: {
    //取消申请退款
    url: 'v3/order/cancelRefoundsApply',
    type: 'post',
  },
  speedUpOrder: {
    //催单
    url: 'v3/order/speedUpOrder',
    type: 'post',
  },
  endService: {
    //结束服务
    url: 'v3/order/EndService',
    type: 'post',
  },
  getComment: {
    //获取评论详情
    url: 'v5/order/comment/getComment',
    type: 'post',
  },
  addOrderComment: {
    //新增评论
    url: 'v5/order/comment/addOrderComment',
    type: 'post',
  },
  getShowTopics: {
    //获取动态话题
    url: 'v5/topic/getShowTopics',
    type: 'post',
  },
  getShowByTopicId: {
    //根据话题id查询作品
    url: 'v5/topic/getShowByTopicId',
    type: 'post',
  },
  getTopicDetail: {
    //根据话题id查询话题详情
    url: 'v5/topic/detail',
    type: 'post',
  },
  deleteUser: {
    //注销账户
    url: 'v5/document/deleteUser',
    type: 'post',
  },
  changeBindPhone: {
    //更换手机号
    url: 'v3/document/changeBindPhone',
    type: 'post',
  },
  loginByWeChatBindPhone: {
    //绑定手机号
    url: 'v6/document/loginByWeChatBindPhone',
    type: 'post',
  },
  bindPhone: {
    //第三方登录(33)，绑定手机号
    url: 'v1/third/h5/bindPhone',
    type: 'post',
  },
  loginForThird: {
    //第三方登录(33)，
    url: 'v1/third/h5/login',
    type: 'post',
  },
  getUnlikeList: {
    //不喜欢的技师列表
    url: 'v5/unlike/list',
    type: 'post',
  },
  //帮助中心列表
  getDocList: {
    url: 'v4/help/docs',
    type: 'get',
  },
  //获取文档详情/帮助中心/规则
  getDocDetail: {
    url: 'v4/help/detail',
    type: 'get',
  },
  //查询帮助文档分类
  getCategory: {
    url: 'v4/help/category',
    type: 'get',
  },
  //积分签到记录
  getSignRecord: {
    url: 'v3/document/point/getMySignRecord',
    type: 'post',
  },
  //积分签到日记
  getPointLogList: {
    url: 'v3/document/point/getPointLogList',
    type: 'post',
  },
  //积分签到提醒
  setSignInRemind: {
    url: 'v3/document/point/SetSignInRemind',
    type: 'post',
  },
  //积分签到
  pointSignIn: {
    url: 'v3/document/point/SignIn',
    type: 'post',
  },
  //积分兑换
  pointExchange: {
    url: 'v3/document/point/ExchangePoint',
    type: 'post',
  },
  //休闲娱乐，积分信息
  getPointInfo: {
    url: 'v3/document/point/info',
    type: 'post',
  },
  //获取作品举报类型
  getAgainstCategory: {
    url: 'v5/show/comment/category',
    type: 'post',
  },
  //举报作品评论
  againstShowComment: {
    url: 'v5/show/comment/against',
    type: 'post',
  },
  //幸运大转盘
  getLuckyDraw: {
    url: 'v6/document/point/LuckyDraw',
    type: 'post',
  },
  //大转盘奖项内容
  getDrawDetail: {
    url: 'v3/document/point/getDrawDetail',
    type: 'post',
  },
  //获取oss上传token
  getOssToken: {
    url: 'v5/oss/token',
    type: 'post',
  },
  //获取所有系统信息
  getSysMessages: {
    url: 'v3/message/getSysMessages',
    type: 'post',
  },
  //一键删除所有系统信息
  deleteAllMessages: {
    url: 'v3/message/deleteAllMessages',
    type: 'post',
  },
  //获取是否可以聊天
  isCanChat: {
    url: 'v3/custom/canChat',
    type: 'post',
  },
  //获取门店列表
  getShopList: {
    url: 'v3/storeshow/list',
    type: 'post',
  },
  //获取门店详情
  getShopDetail: {
    url: 'v3/storeshow/info',
    type: 'post',
  },
  //获取门店技师
  getShopTech: {
    url: 'v3/storetechnician/GetTechnicianListForCustomer',
    type: 'post',
  },
  //创建门店订单
  createStoreOrder: {
    url: 'v5/order/create/CreateStoreOrder',
    type: 'post',
  },
  //禁用申诉
  unbanchar: {
    url: 'v3/document/user/unbanchar',
    type: 'post',
  },
  //根据城市名获取城市code
  getCitycodeByName: {
    url: 'v3/document/area/GetCodeByName',
    type: 'get',
  },
  //斗拱支付获取支付方式
  queryPaymentMode: {
    url: 'pay/control/queryPaymentMode',
    type: 'post',
  },
  //邀请好友排行榜
  getInviteFriendRank: {
    url: 'v3/invite/rankWithTecInviteCustomer',
    type: 'post',
  },
  //已邀请好友
  getInviteFriend: {
    url: 'v3/invite/myInvite',
    type: 'post',
  },
  //已邀请技师
  getInviteTech: {
    url: 'v3/invite/inviteInfoWithTec',
    type: 'post',
  },
  //已邀请技师明细
  getInviteTechDetail: {
    url: 'v3/invite/inviteDetailWithTec',
    type: 'post',
  },
  //邀请技师排行榜
  getInviteTechRank: {
    url: 'v3/invite/rankWithTec',
    type: 'post',
  },
  //获取可加钟项目
  getAddClockProducts: {
    url: 'v4/product/GetAddClockProducts',
    type: 'post',
  },
  //获取分享海报
  getPoster: {
    url: 'v4/poster/userinfo',
    type: 'get',
  },
  //动态关注小红点
  getTimestamp: {
    url: 'v5/show/discover/focus/timestamp',
    type: 'post',
  },
  //技师申请
  createTechnicianApply: {
    url: 'v4/recruit/CreateTechnicianApply',
    type: 'post',
  },
  //获取省市树状结构
  getProvinceCityTree: {
    url: 'v3/document/area/GetProvinceCityTree',
    type: 'get',
  },
  //获取图形验证码
  getCaptcha: {
    url: 'v5/sms/captcha',
    type: 'post',
  },
  //图形验证码校验
  checkCaptcha: {
    url: 'v5/sms/captchaValidate',
    type: 'post',
  },
  //城市合伙人申请
  cityPartnerApply: {
    url: 'v4/recruit/CityPartnerApply',
    type: 'post',
  },
  //获取门店入驻调查问卷信息
  getSurveyForStore: {
    url: 'v3/document/survey/GetSurveyForStore',
    type: 'get',
  },
  //提交门店入驻调查问卷信息
  submitSurveyForStore: {
    url: 'v4/document/survey/SubmitSurveyForStore',
    type: 'post',
  },
  //获取商户联系人
  getCityPartnerPhone: {
    url: 'v3/order/getCityPartnerPhone',
    type: 'post',
  },
  //微信公众号 权限验证配置
  getWxConfig: {
    url: 'v3/wx/public/config',
    type: 'get',
  },
  //客服查询
  getCustomShow: {
    url: 'v3/custom/show',
    type: 'get',
  },
  //查询客服昵称和头像
  getCustomUserList: {
    url: 'v3/custom/userList',
    type: 'post',
  },
  //创建智能回复群聊
  createSmartReplyGroup: {
    url: 'v1/groupChat/createSmartReplyGroup',
    type: 'post',
  },
  //根据问题id自动回答问题
  smartReply: {
    url: 'v1/groupChat/smartReply',
    type: 'post',
  },
  //人工服务
  humanServices: {
    url: 'v1/groupChat/humanServices',
    type: 'post',
  },
  //解散群聊
  destroyGroup: {
    url: 'v1/groupChat/destroyGroup',
    type: 'post',
  },
  //修改用户资料
  modifyUser: {
    url: 'v3/document/modifyUser',
    type: 'post',
  },
  //短信验证码校验
  smsValidate: {
    url: 'v5/sms/validate',
    type: 'post',
  },
  //动态详情更多视频
  moreVideoDetailList: {
    url: 'v5/show/discover/moreVideoDetailList',
    type: 'post',
  },
  //技师列表直接预约获取套餐信息
  directBookingFromTechlist: {
    url: 'v5/document/technician/directAppointment',
    type: 'post',
  },
  //技师列表更多动态
  getTecListShow: {
    url: 'v5/show/client/tecListShow',
    type: 'post',
  },
  //用户撤销订单修改请求
  revokeModifyOrder: {
    url: 'v5/order/modify/RevokeModifyOrder',
    type: 'post',
  },
  //技师申请入驻极速版
  speedApplyIn: {
    url: 'speed/v1/ver/apply',
    type: 'post',
  },
  //获取im聊天客服信息
  getChatSevicerInfo: {
    url: 'v1/groupChat/info',
    type: 'post',
  },
  //提交客服满意度评价
  submitForCustomerService: {
    url: 'v1/groupChat/submitForCustomerService',
    type: 'post',
  },
  //IM聊天室信息上报
  submitChatRoom: {
    url: 'v3/custom/report/chatRoom',
    type: 'post',
  },
  //查看退款协商历史
  getRefundHistory: {
    url: 'v5/orderRefund/GetRefundNegotiationHistoryByOrder',
    type: 'post',
  },
  //申请客服介入
  applyCustomIn: {
    url: 'v4/order/customin',
    type: 'post',
  },
  //取消客服介入
  cancelCustomin: {
    url: 'v3/order/cancelCustomin',
    type: 'post',
  },
  //获取退款原因
  getRefundReason: {
    url: 'v5/orderRefund/getRefundReason',
    type: 'post',
  },
  //获取优惠券活动列表
  getActivityList: {
    url: 'v6/coupons/activity/list',
    type: 'post',
  },
  //领取优惠券
  getActivityCoupon: {
    url: 'v6/coupons/activity/receive',
    type: 'post',
  },
  //提醒领取优惠券
  remindsToGetCoupon: {
    url: 'v6/coupons/activity/remind',
    type: 'post',
  },
  //获取用户调查问卷信息
  getUserSurvey: {
    url: 'v6/document/survey/getUserSurvey',
    type: 'post',
  },
  //提交用户调查问卷信息
  submitUserSurvey: {
    url: 'v6/document/survey/submit/userSurvey',
    type: 'post',
  },
  //获取群玩登录信息
  getGroupPlayInfo: {
    url: 'v1/game/groupPlay/login',
    type: 'get',
  },
  //获取回归问卷调查内容
  getSurveyBack: {
    url: 'v6/document/survey/getUserSurveyBack',
    type: 'post',
  },
  //提交回归问卷调查
  submitSurveyBack: {
    url: 'v6/document/survey/submit/userSurveyBack',
    type: 'post',
  },
  //成长中心-会员权益规则
  getGrowthRuleList: {
    url: 'v5/growthCenter/growthEquityLevelList',
    type: 'post',
  },
  //成长中心-成长值明细
  getGrowthValueRecords: {
    url: 'v5/growthCenter/growthValueRecords',
    type: 'post',
  },
  //成长中心-任务列表
  getGrowthTaskList: {
    url: 'v5/growthCenter/growthTaskList',
    type: 'post',
  },
  //成长中心-成长等级列表
  getGrowthLevelList: {
    url: 'v5/growthCenter/growthLevelList',
    type: 'post',
  },
  //成长中心-领取会员(分享卡)
  getShareVip: {
    url: 'v5/growthCenter/receiveShareVip',
    type: 'post',
  },
  //我的成长等级
  getMyGrowthLevel: {
    url: 'v5/growthCenter/myGrowthLevel',
    type: 'post',
  },
  //领取优惠券
  getGrowthCoupon: {
    url: 'v5/growthCenter/receiveCoupon',
    type: 'post',
  },
  //领取会员(分享卡)
  getShareVip: {
    url: 'v5/growthCenter/receiveShareVip',
    type: 'post',
  },
  //领取会员卡
  getVipCard: {
    url: 'v5/growthCenter/receiveVip',
    type: 'post',
  },
  //分享会员卡海报信息
  shareVipCard: {
    url: 'v4/poster/shareVipCard',
    type: 'get',
  },
  //成长值：会员等级弹框提示
  setGrowthLevelRead: {
    url: 'v5/growthCenter/setGrowthLevelRead',
    type: 'post',
  },
  //获取会员卡分享人信息
  getShareVipUserInfo: {
    url: 'v5/growthCenter/shareVipUserInfo',
    type: 'post',
  },
  //每日分享成功之后回调
  updateUserShare: {
    url: 'v5/userShareRecord/userShare',
    type: 'post',
  },
  //技师统计报表
  getTechReport: {
    url: 'v5/manage/tec/report/statistics',
    type: 'post',
  },
  //技师统计报表说明
  viewInstruction: {
    url: 'v5/manage/tec/report/viewInstruction',
    type: 'post',
  },
  //是否展示优惠券
  getCouponsActivity: {
    url: 'v6/coupons/activity/isShowCouponsActivity',
    type: 'post',
  },
  //技师登录（测试用）
  techLogin: {
    url: 'v4/document/login',
    type: 'post',
  },
  //兑换优惠券
  exchangeCoupon: {
    url: 'v6/coupons/mis/exchangePasswordCoupon',
    type: 'post',
  },
  //获取未关注技师列表
  getNotAttentionTechnicianList: {
    url: 'v5/document/technician/notAttentionTechnicianList',
    type: 'post',
  },
  //根据productCode获取套餐id
  getProductId: {
    url: 'v5/product/getProductId',
    type: 'post',
  },
  //专题页技师列表
  getSpecialTopicList: {
    url: 'v5/document/technician/technicianSpecialTopicList',
    type: 'post',
  },
  //用户请愿
  userPetition: {
    url: 'v5/show/discover/userPetition',
    type: 'post',
  },
  //获取弹框
  getUserPopups: {
    url: 'v5/app/popup/getUserPopups',
    type: 'post',
  },
  //保存用户弹框公告,后面不需要展示
  saveUserPopup: {
    url: 'v5/app/popup/saveUserPopup',
    type: 'post',
  },
  //管理版/对ta的印象数据/客户画像
  getTechFeelData: {
    url: 'v6/order/customer/personas',
    type: 'post',
  },
  //同意/不同意沟通申请
  handleCommunicateApply: {
    url: 'v3/communicate/handleApply',
    type: 'post',
  },
  //修改订单弹框已读不再显示
  isReadModifyOrder: {
    url: 'v5/order/modify/CustomerIsReadModifyOrder',
    type: 'post',
  },
  //商学院
  //查询商学院-banner
  getBannerList: {
    url: 'v2/learn/banner/list',
    type: 'post',
  },
  //查询商学院-首页标签
  getHomeTag: {
    url: 'v2/learn/lesson/homeTag',
    type: 'get',
  },
  //查询商学院-课程表
  getLessonList: {
    url: 'v2/learn/lesson/list',
    type: 'post',
  },
  //商学院-新增学习记录
  saveStudyRecord: {
    url: 'v2/learn/study/save',
    type: 'post',
  },
  //商学院-学习记录列表
  studyList: {
    url: 'v2/learn/study/list',
    type: 'post',
  },
  //商学院-课程收藏表
  collectList: {
    url: 'v2/learn/lessonStar/list',
    type: 'post',
  },
  //商学院-新增课程收藏表
  addCollect: {
    url: 'v2/learn/lessonStar/save',
    type: 'post',
  },
  //商学院-删除全部浏览记录
  deleteAllView: {
    url: 'v2/learn/study/deleteAllView',
    type: 'post',
  },
  //商学院-删除单个浏览记录
  deleteView: {
    url: 'v2/learn/study/deleteViewRecord',
    type: 'post',
  },
  //商学院-课程详情
  lessonDetail: {
    url: 'v2/learn/lesson/info',
    type: 'post',
  },
  //商学院-热门搜索
  hotKeyList: {
    url: 'v2/learn/lesson/hotKeyList',
    type: 'get',
  },
  //管理版：注册技师/合伙人注册
  techRegister: {
    url: 'v5/document/invite/reg',
    type: 'post',
  },
  //未开通城市投票
  voteForCity: {
    url: 'v3/AreaVote/vote',
    type: 'post',
  },
  //区域/城市投票统计信息
  getVoteInfo: {
    url: 'v3/AreaVote/info',
    type: 'post',
  },
  //获取电子合同内容
  getAgreementInfo: {
    url: 'v3/agreement/info',
    type: 'get',
  },
  //获取代付支付方式
  getSharePayMode: {
    url: 'pay/control/payshareorder/paymode',
    type: 'post',
  },
  //获取代付订单详情
  getSharePayOrderInfo: {
    url: 'pay/control/payshareorder/info',
    type: 'post',
  },
  //获取代付订单详情
  payShareOrder: {
    url: 'pay/control/payshareorder/pay',
    type: 'post',
  },
  //绑定微信
  bindWechatByCode: {
    url: 'v3/wx/public/BindWxPublicByCode',
    type: 'post',
  },
  //获取技师信用分
  getMerchantCreditScoreList: {
    url: 'v5/manager/merchantCreditScore/getMerchantCreditScoreList',
    type: 'post',
  },
  //获取语音验证码
  getVoiceCode: {
    url: 'v5/sms/sendVoiceCode',
    type: 'post',
  },
  //获取海外合伙人招募城市
  getForeignCity: {
    url: 'v4/recruit/GetForeignCity',
    type: 'get',
  },
  //获取用户openId
  getWxOpenId: {
    url: 'v3/wx/public/getWxPublic',
    type: 'post',
  },
  //获取配置数据
  getConfig: {
    url: 'v3/document/cfg/GROWTH_LEVEL_ICON_CONFIG',
    type: 'get',
  },
  //获取配置数据
  getCodeByAdcode: {
    url: 'v3/document/area/GetCodeByAdcode',
    type: 'get',
  },
  //获取用户sig
  getImSig: {
    url: 'v3/custom/GetImSig',
    type: 'get',
  },
  //获取商家信息
  getCityPartnerInfo: {
    url: 'v5/cityPartner/show/detail',
    type: 'post',
  },
  //获取会员日折扣
  getVipDiscount: {
    url: 'v3/document/cfg/VIP_DISCOUNT',
    type: 'get',
  },
  //获取修改订单可预约时间
  getCanModifyOrderTime: {
    url: 'v5/order/modify/GetModifyOrderTime',
    type: 'post',
  },
  // 往约年会用户信息
  getWynhWxUserInfo: {
    url: 'v1/wynh/getWxUserInfo',
    type: 'post',
  },
  // 往约年会用户报名
  signUpWynh: {
    url: 'v1/wynh/signUp',
    type: 'post',
  },
  // 获取系统时间
  getEpoch: {
    url: 'document/cfg/epoch',
    type: 'get',
  },
  // 红包雨活动时间
  redPackTimeRange: {
    url: 'v3/document/cfg/REDPACK_TIME_RANGE',
    type: 'get',
  },
  // 获取优惠券批次Id
  redPackCouponBatchId: {
    url: 'v3/document/cfg/REDPACK_COUPON_BATCH_ID',
    type: 'get',
  },
  // 优惠券是否领取过
  isReceiveByBatchId: {
    url: 'v6/coupons/activity/isReceiveByBatchId',
    type: 'post',
  },
  // 汗滴首页推荐技师
  getHdRecommendTech: {
    url: 'v5/document/technician/handiRecommendTechnicianList',
    type: 'post',
  },
  // 汗滴开通城市
  getHandiOpenProvinceGroup: {
    url: 'v3/document/area/GetHandiOpenProvinceGroup',
    type: 'get',
  },
  //汗滴开通城市
  getHdCityConfig: {
    url: 'v3/document/cfg/HANDI_CITY_CODE',
    type: 'get',
  },
  //足迹技师列表
  getMyFootprintTechList: {
    url: 'v5/document/fav/myFootprintTechList',
    type: 'post',
  },
  getHdProducts: {
    //获取汗滴套餐
    url: 'v3/product/handiList',
    type: 'post',
  },
  cleanBindWeChat: {
    //解除微信绑定
    url: 'v3/document/CleanBindWeChat',
    type: 'post',
  },
  getCarFeeByOrder: {
    //预下单获取滴滴车费
    url: 'order/getCarFee',
    type: 'post',
  },
  myInviteProgress: {
    //邀请进度与收益
    url: 'invite/myInviteProgress',
    type: 'get',
  },
  listInviteCusRank: {
    //邀请用户排行榜
    url: 'invite/listInviteCusRank',
    type: 'get',
  },
  listInviteCus: {
    //邀请记录
    url: 'invite/listInviteCus',
    type: 'get',
  },
  getInviteCusRewardDetail: {
    //奖励明细
    url: 'invite/getInviteCusRewardDetail',
    type: 'get',
  },
}
const req = apiInit(urlList)
export { req }
